import responsiveIframe from './_components/responsive-iframe';

function initEvents() {
    const menuBtn = document.querySelector('.menu-btn.open');
    menuBtn.addEventListener('click', function() {
        document.body.classList.add('menu-open')
    });
    responsiveIframe.iframeListener();
}

function initLazyloadImages() {
    const config = {
        rootMargin: '0px 0px 50px 0px',
        threshold: 0
    };
    
    let observer = new IntersectionObserver(function(entries, self) {
    entries.forEach(function(entry) {
        if(entry.isIntersecting) {
        entry.target.src = entry.target.dataset.src;
        self.unobserve(entry.target);
        }
    });
    }, config);

    const lazyImages = document.querySelectorAll('.lazy');
    lazyImages.forEach(function(image) {
        observer.observe(image);
    });
}

function bootstrap() {
    initEvents();
    initLazyloadImages();
}

document.addEventListener('DOMContentLoaded', bootstrap, false);
